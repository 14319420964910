import React, { useEffect, useState } from "react";
import DashboardLayout from "../components/layouts/DashboardLayout";
import PageTitle from "../components/PageTitle";
import styled from "styled-components";
import { FaUserTie } from "react-icons/fa";
import { IoIosArrowDropdown } from "react-icons/io";
import { useAuth } from "../context/userContext";
import { useDispatch, useSelector } from "react-redux";
import { clearTeamErrors, getMyTeam } from "../redux/actions/teamAction";
import { toast } from "react-toastify";
import TeamTree from "../components/TeamTree";
import { useNavigate, useParams } from "react-router-dom";

// const TreeNode = ({ user }) => {
//   const dispatch = useDispatch();
//   const [children, setChildren] = useState([]);
//   const [expanded, setExpanded] = useState({ show: false, id: null });

//   const { loading, data, error } = useSelector((state) => state.myTeam);

//   useEffect(() => {
//     if (data?.team?.length) {
//       setChildren(data?.team);
//     } else {
//       setChildren([]);
//     }
//   }, [data?.team]);

//   const handleClick = async (id, fullName) => {
//     if (expanded.show) {
//       setExpanded({ show: false, id: "" });
//       return;
//     } else if (!expanded.show) {
//       setExpanded({ show: true, id: id });
//     }

//     dispatch(getMyTeam(id, 1));
//   };

//   // GET MY TEAM STAR

//   useEffect(() => {
//     if (error) {
//       toast.error(error);
//       dispatch(clearTeamErrors());
//     }
//   }, [error, dispatch]);

//   // GET MY TEAM ENDS

//   return (
//     <>
//       <div
//         onClick={() => handleClick(user?._id, user?.fullName)}
//         style={{
//           cursor: "pointer",
//           marginBottom: "5px",
//           color: "#000",
//           background: "lightgrey",
//           padding: "10px",
//           borderRadius: "6px",
//         }}
//       >
//         {user?.fullName}({user?.panNumber}) {expanded.show ? "-" : "+"}
//       </div>
//       {loading && <p>Loading...</p>}
//       {error && <p>{error}</p>}
//       {expanded.show && children && children.length > 0 && (
//         <div style={{ marginLeft: "20px" }}>
//           {children.map((child) => (
//             <TreeNode key={child._id} user={child} />
//           ))}
//         </div>
//       )}
//       {expanded.show && children && children.length === 0 && !loading && (
//         <p style={{ color: "red" }}>No team members found of this user.</p>
//       )}
//     </>
//   );
// };

// const Tree = ({ rootUser }) => {
//   return rootUser && <TreeNode user={rootUser} />;
// };

const MyTeam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const user = auth?.user;
  const [userId, setUserId] = useState("");

  const params = useParams();
  const id = params?.userId;

  console.log("params", params);

  const { loading, data, error } = useSelector((state) => state.myTeam);
  console.log("userId", userId);
  console.log("data", data);

  const myTeam = data?.team;
  const myTeamSize = data?.team;

  // useEffect(() => {
  //   if (user?._id) {
  //     setUserId(user?._id);
  //   }
  // }, [user?._id]);

  useEffect(() => {
    if (id) {
      dispatch(getMyTeam(id, 1));
    }
  }, [id, dispatch]);

  const handleGetTeam = (user, teamSize) => {
    if (teamSize > 0) {
      navigate(`/my-team/${user?._id}`);
    } else {
      toast.warning(`${user?.fullName} have no team members`);
    }
  };

  return (
    <div className="myTeam_main">
      <DashboardLayout>
        <PageTitle title={`My Team/Associates - ${myTeam?.length > 0 ? myTeam?.length : 0}`} />
        <div className="userContent" style={{ marginTop: "10px" }}>
          <div className="container">
            {/* {user?._id && <Tree rootUser={user} />} */}
            {loading ? (
              <div className="loader_div_main">
                <div></div>
              </div>
            ) : (
              <div
                className="myTeam_sec"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                }}
              >
                {!myTeam?.length ? (
                  <div
                    className="bg-red"
                    style={{
                      padding: "20px 10px",
                      textAlign: "center",
                      border: "1px solid #f2f2f2",
                      borderRadius: "7px",
                      fontSize: "18px",
                    }}
                  >
                    Associates not found or Something went wrong! <br /> try again,
                    after sometime.
                  </div>
                ) : (
                  myTeam?.map((item) => {
                    return (
                      <TeamTree
                        key={item?._id}
                        teamCount={item?.teamSize}
                        name={item?.fullName}
                        panNumber={item?.panNumber}
                        onClick={() => handleGetTeam(item, item?.teamSize)}
                      />
                    );
                  })
                )}
              </div>
            )}
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default MyTeam;
